import './About.css';
import Photo1 from './assets/5.jpg';
import Photo2 from './assets/6.jpg';
import Google from './Google';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import map from './assets/map.PNG';
function About() {



  return (
    <div className="About">
        <div className='AboutGroups'>
            <div className='About1'>
            <img src={Photo1}/>
            <div>
              <span>Jūsų vaikas mėgsta dainuoti ir muzikuoti, o gal tiesiog ieškote naujo mylimiausio būrelio?</span>
              <span>
              Prisijunkite prie neformalaus ugdymo būrelio 
              „Talentų Alėjos"!  "Talentų Alėja" - tai muzikos studija, kurios pagrindinis siekis yra lavinti vaikų muzikalumą, kūrybiškumą, socialumą bei pasitikėjimą savimi. Užsiėmimų metu ugdomi bendri muzikos
               teorijos pagrindai, sceninė kultūra, ritmikos pojūtis.
              Taip pat - dainuojama, grojama akustiniais instrumentais,
              žaidžiami įvairūs muzikiniai žaidimai. Pirmas užsiėmimas
              visiškai nemokamas, susisiekite bei suderinsime Jums patogiausią laiką.
              </span>
            </div>
            </div>
            <div className='About2'>
              <div>
              <span>Užsiėmimų vieta</span>
                <span>Užsiėmimų vieta - Garliavos Adomo Mitkaus pagrindinė mokykla - Aktų salė (Vytauto g. 4, Garliava 53270) </span>
                <span>Užsiėmimų trukmė - 1 akademinė valanda (apie 45 min.)</span>
                <span>Būrelį veda Garliavos Adomo Mitkaus pagrindinės mokyklos muzikos mokytoja Auksė Smilgytė.</span>
              </div>
              <div className='GoogleButton'>
                <a href="https://www.google.com/maps/place/Vytauto+g.+4,+Garliava+53270/@54.8153925,23.8746784,17z/data=!3m1!4b1!4m5!3m4!1s0x46e72481cb3fc0ed:0x91854ced7a8b11e5!8m2!3d54.8153925!4d23.8746784" target="_blank">Google Maps</a><AddLocationAltIcon fontSize="large"/>
              </div>
              <div className='Google'>
              <a href="https://www.google.com/maps/place/Vytauto+g.+4,+Garliava+53270/@54.8153925,23.8746784,17z/data=!3m1!4b1!4m5!3m4!1s0x46e72481cb3fc0ed:0x91854ced7a8b11e5!8m2!3d54.8153925!4d23.8746784" target="_blank"><img src={map}/></a>
              </div>
            </div>
            <div className='About3'>
            <img src={Photo2}/>
              <div>
                  <span>Jūsų vaikas mėgsta dainuoti ir muzikuoti, o gal tiesiog ieškote naujo mylimiausio būrelio?</span>
                  <span>"Talentų Alėja" - tai muzikos studija, kurios pagrindinis siekis yra lavinti vaikų muzikalumą, kūrybiškumą, socialumą bei pasitikėjimą savimi.</span>
                  <span>Užsiėmimų metu ugdomi bendri muzikos teorijos pagrindai, sceninė kultūra, ritmikos pojūtis. Taip pat - dainuojama, grojama akustiniais instrumentais, žaidžiami įvairūs muzikiniai žaidimai pagal inovatyviausias kompiuterines programas.</span>
              </div>
              
            </div>
        </div>
    </div>
  );
}

export default About;
