import './Teacher.css';
import Photo1 from './assets/1.jpg';
function Teacher() {



  return (
    <div className="About">
        <div className='Teacher'>
            <div className='AboutTeacher'>
            <img src={Photo1} alt="Mokytoja"/>
            <div>
              <span>Mokytoja Auksė</span>
              <span>
              Muzikos mokytoja esu jau virš 30 metų, tačiau būtent per pastaruosius keletą metų vis 
              dažniau išgirsdavau tėvelių norą, kad jų vaiką mokyčiau ir padėčiau visapusiškai tobulėti 
              šiek tiek privačiau, nei mokykloje - kuomet mažesnėje vaikų grupėje galima išties įsiklausyti į 
              vaiko norus ir jo vidinius talentus, kurie atsiskleidžia užsiėmimų metu.
              </span>
            </div>
            </div>
        </div>
    </div>
  );
}

export default Teacher;
