import './Header.css';
import Logo from'./assets/LogoN.png';
import { Link, animateScroll as scroll } from "react-scroll";
import {Link as Linkk} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Slide } from 'react-slideshow-image';
import img1 from './assets/S1.jpg';
import img2 from './assets/S2.jpg';
import img3 from './assets/S3.jpg';
import img4 from './assets/S4.jpg';
import img5 from './assets/S5.jpg';
import img6 from './assets/S6.jpg';
import img7 from './assets/S7.jpg';
import { slide as Menu } from 'react-burger-menu'
import HomeIcon from '@mui/icons-material/Home';


import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: img1,
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: img2,
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: img3,
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original: img4,
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: img5,
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: img6,
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: img7,
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
];


function Header() {
    const slideImages = [
        img1,
       img2 ,
        img3,
      ];
      const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true
      }

      const shoot = () => {
        window.location.href = "/"
      }
  return (
    <div className="Header">
      <div className='whiteBackground'></div>
      <Menu width={ 300 }>
      <ul>
          <li><Linkk to="/">PAGRINDINIS</Linkk></li>
          <li><Linkk to="/reviews">ATSILIEPIMAI</Linkk></li>
          <li><Linkk to="/gallery">GALERIJA</Linkk></li>
          <li><a className='registerLink' href="https://docs.google.com/forms/d/e/1FAIpQLScWxXWCx5mYo7vzKtBxtB-jZ5CrcOps6Og2-d-DoX8n1Ehy7A/viewform">BŪRELIS</a></li>
          <li><a className='registerLink1' href="https://forms.gle/AR59pcR78mmK3nYK9">STOVYKLA</a></li>
          <li><a href="https://platforma.talentualeja.lt/">PLATFORMA</a></li>
      </ul>
      </Menu>
        <div className='NavBar'>
        <img src={Logo} onClick={shoot}/>
            <nav className='Nav1'>
                <ul>
                    {/* <li><Link to="About" spy={true} smooth={true} duration={800}>APIE</Link></li> */}
                    <li><Linkk to="/"><HomeIcon/></Linkk></li>
                    <li><Linkk to="/reviews">ATSILIEPIMAI</Linkk></li>
                    {/* <li><Link to="Prices" spy={true} smooth={true} duration={800}>KAINA</Link></li> */}
                    <li><Linkk to="/gallery">GALERIJA</Linkk></li>
                    <li><Link to="Footer" spy={true} smooth={true} duration={800}>KONTAKTAI</Link></li>
                    {/* <li><a className='registerLink' href="https://docs.google.com/forms/d/e/1FAIpQLScWxXWCx5mYo7vzKtBxtB-jZ5CrcOps6Og2-d-DoX8n1Ehy7A/viewform">REGISTRACIJA</a></li> */}
                    <li><a className='registerLink' href="https://docs.google.com/forms/d/e/1FAIpQLScWxXWCx5mYo7vzKtBxtB-jZ5CrcOps6Og2-d-DoX8n1Ehy7A/viewform">BŪRELIS</a></li>
                    <li><a className='registerLink1' href="https://forms.gle/AR59pcR78mmK3nYK9">STOVYKLA</a></li>
                    <li><a href="https://platforma.talentualeja.lt/">PLATFORMA</a></li>
                </ul>
            </nav>
        </div>
        <div className='Slides'>
            <ImageGallery 
            items={images}
            showIndex={false} 
            disableKeyDown={true} showPlayButton={false} showFullscreenButton={false} showNav={true} autoPlay={true} showBullets={false} slideDuration={2000}  infinite={false} slideInterval={5000} lazyLoad={true} showThumbnails={false}/>
        </div>
        <Link className="link" id="ulink" to="About" spy={true} smooth={true} duration={800}><KeyboardArrowDownIcon sx={{ fontSize: 100 }} className='Arrow'/></Link>
    </div>
  );
}

export default Header;