import './Prices.css';

function Prices() {
  return (
    <div className="Prices">
        <span>
        KAINOS
        </span>
        <div className='PriceGroups'>
            <div className='PriceGroup1'>
            <span>4 užsiėmimai</span>
              <ul>
                <li><span>4 užsiėmimai per mėnesį</span></li>
                <li><span>50€ per mėn.</span></li>
                <li><span>12.5€ per užsiėmimą</span></li>
              </ul>
            </div>
            <div className='PriceGroup2'>
              <span>8 užsiėmimai</span>
              <ul>
                <li><span>8 užsiėmimai per mėnesį </span></li>
                <li><span>90€ per mėn.</span></li>
                <li><span>11.5€ per užsiėmimą</span></li>
              </ul>
            </div>
            <div className='PriceGroup3'>
            <span>12 užsiėmimų</span>
              <ul>
                <li><span>12 užsiėmimų per mėnesį </span></li>
                <li><span>120€ per mėn.</span></li>
                <li><span>10€ per užsiėmimą</span></li>
              </ul>
            </div>
            <div className='PriceGroup4'>
            <span>Individualūs užsiėmimai</span>
              <ul>
                <li><span>Neribotas užsiėmimų kiekis</span></li>
                <li><span>20€ per užsiėmimą</span></li>
                <li><span>Pritaikoma specialiai pagal vaiko norus ir siekius.</span></li>
              </ul>
            </div>
        </div>
    </div>
  );
}

export default Prices;