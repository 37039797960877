import './Gallery.css';
import Header from './Header.js'
import './Catalog5.css'
import {Link as Linkk} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from './Footer.js'
import photo1 from './assets/Gallery/2022-12/1.jpeg';
import photo2 from './assets/Gallery/2022-12/2.jpeg';
import photo3 from './assets/Gallery/2022-12/3.jpeg';
import photo4 from './assets/Gallery/2022-12/4.jpeg';
import photo5 from './assets/Gallery/2022-12/5.jpeg';
import photo6 from './assets/Gallery/2022-12/6.jpeg';
import photo7 from './assets/Gallery/2022-12/7.jpeg';
import photo8 from './assets/Gallery/2022-12/8.jpeg';
import photo9 from './assets/Gallery/2022-12/1.jpeg';
import photo10 from './assets/Gallery/2022-12/10.jpeg';
import photo11 from './assets/Gallery/2022-12/11.jpeg';
import photo12 from './assets/Gallery/2022-12/12.jpeg';
import photo13 from './assets/Gallery/2022-12/13.jpeg';
import photo14 from './assets/Gallery/2022-12/14.jpeg';
import photo15 from './assets/Gallery/2022-12/15.jpeg';
import photo16 from './assets/Gallery/2022-12/16.jpeg';
import photo17 from './assets/Gallery/2022-12/17.jpeg';
import photo18 from './assets/Gallery/2022-12/18.jpeg';
import photo19 from './assets/Gallery/2022-12/19.jpeg';
import photo20 from './assets/Gallery/2022-12/20.jpeg';
import photo21 from './assets/Gallery/2022-12/21.jpeg';

const images = [
  {
    original: photo1,
    thumbnail: photo1,
    thumbnailHeight: '50',
  },
  {
    original: photo2,
    thumbnail: photo2,
    thumbnailHeight: '50',
  },
  {
    original: photo3,
    thumbnail: photo3,
    thumbnailHeight: '50',
  },
  {
    original: photo4,
    thumbnail: photo4,
    thumbnailHeight: '50',
  },
  {
    original: photo5,
    thumbnail: photo5,
    thumbnailHeight: '50',
  },
  {
    original: photo6,
    thumbnail: photo6,
    thumbnailHeight: '50',
  },
  {
    original: photo7,
    thumbnail: photo7,
    thumbnailHeight: '50',
  },
  {
    original: photo8,
    thumbnail: photo8,
    thumbnailHeight: '50',
  },
  {
    original: photo9,
    thumbnail: photo9,
    thumbnailHeight: '50',
  },
  {
    original: photo10,
    thumbnail: photo10,
    thumbnailHeight: '50',
  },
  {
    original: photo11,
    thumbnail: photo11,
    thumbnailHeight: '50',
  },
  {
    original: photo12,
    thumbnail: photo12,
    thumbnailHeight: '50',
  },
  {
    original: photo13,
    thumbnail: photo13,
    thumbnailHeight: '50',
  },
  {
    original: photo14,
    thumbnail: photo14,
    thumbnailHeight: '50',
  },
  {
    original: photo15,
    thumbnail: photo15,
    thumbnailHeight: '50',
  },
  {
    original: photo16,
    thumbnail: photo16,
    thumbnailHeight: '50',
  },
  {
    original: photo17,
    thumbnail: photo17,
    thumbnailHeight: '50',
  },
  {
    original: photo18,
    thumbnail: photo18,
    thumbnailHeight: '50',
  },
  {
    original: photo19,
    thumbnail: photo19,
    thumbnailHeight: '50',
  },
  {
    original: photo20,
    thumbnail: photo20,
    thumbnailHeight: '50',
  },
  {
    original: photo21,
    thumbnail: photo21,
    thumbnailHeight: '50',
  },
];
function Catalog5() {
  return (
    <div className="Gallery">
      <Header/>
      <div className='gallerynameblock'>
      <div className='goBack'>
    <Linkk to="/gallery"><ArrowBackIcon/><span>Atgal</span></Linkk>
    </div>
      <span className='galleryname'>2022-12 Kalėdinė šventė</span>
      </div>
      <div className="Catalog5">
          <ImageGallery items={images}  showIndex={true}/>
      </div>
      <Footer/>
    </div>
  );
}

export default Catalog5;
