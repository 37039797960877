import './App.css';
import Header from './Header.js'
import Prices from './Prices.js'
import About from './About.js'
import Footer from './Footer.js'
import "@fontsource/montserrat";
import Reviews from './Reviews';
import Teacher from './Teacher'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes 
  } from "react-router-dom";


function Home() {
    return (
      <div className="App">
            <Header/>
            <About/>
            <Prices />
            <Teacher/>
            <Footer/>
      </div>
    );
  }
  
  export default Home;