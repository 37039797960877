import './Gallery.css';
import Header from './Header.js'
import ImageGallery from 'react-image-gallery';
import Footer from './Footer.js'
import Catalog from './Catalog.js'
import {Link as Linkk} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes 
} from "react-router-dom";
const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

const f = "|f";

function Gallery() {
  return (
    <div className="Gallery">
      <Header/>
      <div className='gallerynameblock'>
      <span className='galleryname'>GALERIJA</span>
      </div>
      <div className='gallerygrid'>
            <div className='gallerycard' onClick={f}>
            <span>2021 Spalio mėn.</span>
            <Linkk to="/gallery/catalog"><span>Atidarymo šventė</span></Linkk>
            </div>
            <div className='gallerycard1'>
            <span>2021 Gruodžio mėn.</span>
            <Linkk to="/gallery/catalog1"><span>Kalėdinė šventė</span></Linkk>
            </div>
            <div className='gallerycard2'>
            <span>2022 Birželio mėn.</span>
            <Linkk to="/gallery/catalog2"><span>Apdovanojimai</span></Linkk>
            </div>
            <div className='gallerycard3'>
            <span>2022 Liepos mėn.</span>
            <Linkk to="/gallery/catalog3"><span>Stovykla</span></Linkk>
            </div>
            <div className='gallerycard4'>
            <span>2022 Spalio mėn.</span>
            <Linkk to="/gallery/catalog4"><span>Pirmojo gimtadienio šventė</span></Linkk>
            </div>
            <div className='gallerycard5'>
            <span>2022 Gruodžio mėn.</span>
            <Linkk to="/gallery/catalog5"><span>Kalėdinė šventė</span></Linkk>
            </div>
            <div className='gallerycard6'>
            <span>2023 Liepos mėn.</span>
            <Linkk to="/gallery/catalog6"><span>Talentų Alėjos koncertas</span></Linkk>
            </div>
            <div className='gallerycard7'>
            <span>2023 Liepos mėn.</span>
            <Linkk to="/gallery/catalog7"><span>Vasaros stovykla</span></Linkk>
            </div>
        </div>
      <Footer/>
    </div>
  );
}

export default Gallery;
