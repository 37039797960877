import './App.css';
import Header from './Header.js'
import Prices from './Prices.js'
import About from './About.js'
import Footer from './Footer.js'
import "@fontsource/montserrat";
import Reviews from './Reviews';
import Home from './Home.js'
import Gallery from './Gallery.js'
import Login from './Login.js'
import Catalog from './Catalog.js'
import Catalog1 from './Catalog1.js'
import Catalog2 from './Catalog2.js'
import Catalog3 from './Catalog3.js'
import Catalog4 from './Catalog4.js'
import Catalog5 from './Catalog5.js'
import Catalog6 from './Catalog6.js'
import Catalog7 from './Catalog7.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes 
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/gallery" element={<Gallery/>} />
              <Route path="/reviews" element={<Reviews/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/gallery/catalog" element={<Catalog/>} />
              <Route path="/gallery/catalog1" element={<Catalog1/>} />
              <Route path="/gallery/catalog2" element={<Catalog2/>} />
              <Route path="/gallery/catalog3" element={<Catalog3/>} />
              <Route path="/gallery/catalog4" element={<Catalog4/>} />
              <Route path="/gallery/catalog5" element={<Catalog5/>} />
              <Route path="/gallery/catalog6" element={<Catalog6/>} />
              <Route path="/gallery/catalog7" element={<Catalog7/>} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;