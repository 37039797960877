import './Footer.css';
import { Link, animateScroll as scroll } from "react-scroll";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
function Footer() {
  return (
    <div className="Footer">
        <div className='MainContainer'>
            <div className='MainContainerFirst'>
            <span>REKVIZITAI</span>
            <div className='MainInfo'>
                <span class="bold">MB "Talentų alėja"</span><br/>
                <span className='bold'>Įmonės kodas :</span><span> 305887107</span><br/>
                <span className='bold'>Vadovas :</span><span> Auksė Smilgytė, direktorė</span><br/>
                <span className='bold'>Adresas :</span><span> Vytauto g. 4, Garliava, LT-53270 Kauno r.</span><br/>
            </div>
            </div>
            <div className='MainContainerSecond'>
            <span>SUSISIEKITE</span>
            <div className='MainInfo'>
                <span className='bold'>Tel. nr. :</span><span> +37065072959</span><br/>
                <span className='bold'>El. paštas :</span><span> talentualeja@gmail.com</span>
            </div>
            </div>
        </div>
        <div className='SecondBlock'>
        <Link className="link" to="Header" spy={true} smooth={true} duration={800}><ArrowCircleUpIcon sx={{ fontSize: 100 }} className='Arrow'/></Link>
            <div className='Socmedia'>
                <span><a href="https://www.linkedin.com/company/talentu-aleja/about/" target="blank"><LinkedInIcon sx={{ fontSize: 60 }}/></a></span><br/>
                <span><a href="https://www.instagram.com/talentu.aleja/"  target="blank"><InstagramIcon sx={{ fontSize: 60 }}/></a></span><br/>
                <span><a href="https://www.facebook.com/talentu.aleja"  target="blank"><FacebookIcon sx={{ fontSize: 60 }}/></a></span>
            </div>
        </div>
    </div>
  );
}

export default Footer;
