import './Gallery.css';
import Header from './Header.js'
import './Catalog2.css'
import {Link as Linkk} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImageGallery from 'react-image-gallery';
import Footer from './Footer.js'
import photo1 from './assets/Gallery/2022-06_apdovanojimai/1.jpg';
import photo2 from './assets/Gallery/2022-06_apdovanojimai/2.jpg';
import photo3 from './assets/Gallery/2022-06_apdovanojimai/3.jpg';
import photo4 from './assets/Gallery/2022-06_apdovanojimai/4.jpg';
import photo5 from './assets/Gallery/2022-06_apdovanojimai/5.jpg';
import photo6 from './assets/Gallery/2022-06_apdovanojimai/6.jpg';
import photo7 from './assets/Gallery/2022-06_apdovanojimai/7.jpg';
import photo8 from './assets/Gallery/2022-06_apdovanojimai/8.jpg';
import photo9 from './assets/Gallery/2022-06_apdovanojimai/1.jpg';
import photo10 from './assets/Gallery/2022-06_apdovanojimai/10.jpg';
import photo11 from './assets/Gallery/2022-06_apdovanojimai/11.jpg';
import photo12 from './assets/Gallery/2022-06_apdovanojimai/12.jpg';
import photo13 from './assets/Gallery/2022-06_apdovanojimai/13.jpg';
import photo14 from './assets/Gallery/2022-06_apdovanojimai/14.jpg';
import photo15 from './assets/Gallery/2022-06_apdovanojimai/15.jpg';
import photo16 from './assets/Gallery/2022-06_apdovanojimai/16.jpg';
import photo17 from './assets/Gallery/2022-06_apdovanojimai/17.jpg';
import photo18 from './assets/Gallery/2022-06_apdovanojimai/18.jpg';
import photo19 from './assets/Gallery/2022-06_apdovanojimai/19.jpg';
import photo20 from './assets/Gallery/2022-06_apdovanojimai/20.jpg';
import photo21 from './assets/Gallery/2022-06_apdovanojimai/21.jpg';
import photo22 from './assets/Gallery/2022-06_apdovanojimai/22.jpg';
import photo23 from './assets/Gallery/2022-06_apdovanojimai/23.jpg';
import photo24 from './assets/Gallery/2022-06_apdovanojimai/24.jpg';
import photo25 from './assets/Gallery/2022-06_apdovanojimai/25.jpg';
import photo26 from './assets/Gallery/2022-06_apdovanojimai/26.jpg';
import photo27 from './assets/Gallery/2022-06_apdovanojimai/27.jpg';
import photo28 from './assets/Gallery/2022-06_apdovanojimai/28.jpg';
import photo29 from './assets/Gallery/2022-06_apdovanojimai/29.jpg';


const images = [
  {
    original: photo1,
    thumbnail: photo1,
    thumbnailHeight: '50',
  },
  {
    original: photo2,
    thumbnail: photo2,
    thumbnailHeight: '50',
  },
  {
    original: photo3,
    thumbnail: photo3,
    thumbnailHeight: '50',
  },
  {
    original: photo4,
    thumbnail: photo4,
    thumbnailHeight: '50',
  },
  {
    original: photo5,
    thumbnail: photo5,
    thumbnailHeight: '50',
  },
  {
    original: photo6,
    thumbnail: photo6,
    thumbnailHeight: '50',
  },
  {
    original: photo7,
    thumbnail: photo7,
    thumbnailHeight: '50',
  },
  {
    original: photo8,
    thumbnail: photo8,
    thumbnailHeight: '50',
  },
  {
    original: photo9,
    thumbnail: photo9,
    thumbnailHeight: '50',
  },
  {
    original: photo10,
    thumbnail: photo10,
    thumbnailHeight: '50',
  },
  {
    original: photo11,
    thumbnail: photo11,
    thumbnailHeight: '50',
  },
  {
    original: photo12,
    thumbnail: photo12,
    thumbnailHeight: '50',
  },
  {
    original: photo13,
    thumbnail: photo13,
    thumbnailHeight: '50',
  },
  {
    original: photo14,
    thumbnail: photo14,
    thumbnailHeight: '50',
  },
  {
    original: photo15,
    thumbnail: photo15,
    thumbnailHeight: '50',
  },
  {
    original: photo16,
    thumbnail: photo16,
    thumbnailHeight: '50',
  },
  {
    original: photo17,
    thumbnail: photo17,
    thumbnailHeight: '50',
  },
  {
    original: photo18,
    thumbnail: photo18,
    thumbnailHeight: '50',
  },
  {
    original: photo19,
    thumbnail: photo19,
    thumbnailHeight: '50',
  },
  {
    original: photo20,
    thumbnail: photo20,
    thumbnailHeight: '50',
  },
  {
    original: photo21,
    thumbnail: photo21,
    thumbnailHeight: '50',
  },
  {
    original: photo22,
    thumbnail: photo22,
    thumbnailHeight: '50',
  },
  {
    original: photo23,
    thumbnail: photo23,
    thumbnailHeight: '50',
  },
  {
    original: photo24,
    thumbnail: photo24,
    thumbnailHeight: '50',
  },
  {
    original: photo25,
    thumbnail: photo25,
    thumbnailHeight: '50',
  },
  {
    original: photo26,
    thumbnail: photo26,
    thumbnailHeight: '50',
  },
  {
    original: photo27,
    thumbnail: photo27,
    thumbnailHeight: '50',
  },
  {
    original: photo28,
    thumbnail: photo28,
    thumbnailHeight: '50',
  },
  {
    original: photo29,
    thumbnail: photo29,
    thumbnailHeight: '50',
  },
];
function Catalog2() {
  return (
    <div className="Gallery">
      <Header/>
      <div className='gallerynameblock'>
      <div className='goBack'>
    <Linkk to="/gallery"><ArrowBackIcon/><span>Atgal</span></Linkk>
    </div>
      <span className='galleryname'>2022-06 Apdovanojimai</span>
      </div>
      <div className="Catalog2">
          <ImageGallery items={images}  showIndex={true}/>
      </div>
      <Footer/>
    </div>
  );
}

export default Catalog2;
