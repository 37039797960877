import './Gallery.css';
import Header from './Header.js'
import './Catalog3.css'
import {Link as Linkk} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from './Footer.js'
import photo1 from './assets/Gallery/2022-07_stovykla/1.jpg';
import photo2 from './assets/Gallery/2022-07_stovykla/2.jpg';
import photo3 from './assets/Gallery/2022-07_stovykla/3.jpg';
import photo4 from './assets/Gallery/2022-07_stovykla/4.jpg';
import photo5 from './assets/Gallery/2022-07_stovykla/5.jpg';
import photo6 from './assets/Gallery/2022-07_stovykla/6.jpg';
import photo7 from './assets/Gallery/2022-07_stovykla/7.jpg';
import photo8 from './assets/Gallery/2022-07_stovykla/8.jpg';
import photo9 from './assets/Gallery/2022-07_stovykla/1.jpg';
import photo10 from './assets/Gallery/2022-07_stovykla/10.jpg';
import photo11 from './assets/Gallery/2022-07_stovykla/11.jpg';
import photo12 from './assets/Gallery/2022-07_stovykla/12.jpg';
import photo13 from './assets/Gallery/2022-07_stovykla/13.jpg';
import photo14 from './assets/Gallery/2022-07_stovykla/14.jpg';
import photo15 from './assets/Gallery/2022-07_stovykla/15.jpg';
import photo16 from './assets/Gallery/2022-07_stovykla/16.jpg';
import photo17 from './assets/Gallery/2022-07_stovykla/17.jpg';
import photo18 from './assets/Gallery/2022-07_stovykla/18.jpg';
import photo19 from './assets/Gallery/2022-07_stovykla/19.jpg';
import photo20 from './assets/Gallery/2022-07_stovykla/20.jpg';
import photo21 from './assets/Gallery/2022-07_stovykla/21.jpg';
import photo22 from './assets/Gallery/2022-07_stovykla/22.jpg';
import photo23 from './assets/Gallery/2022-07_stovykla/23.jpg';
import photo24 from './assets/Gallery/2022-07_stovykla/24.jpg';
import photo25 from './assets/Gallery/2022-07_stovykla/25.jpg';
import photo26 from './assets/Gallery/2022-07_stovykla/26.jpg';
import photo27 from './assets/Gallery/2022-07_stovykla/27.jpg';
import photo28 from './assets/Gallery/2022-07_stovykla/28.jpg';
import photo29 from './assets/Gallery/2022-07_stovykla/29.jpg';
import photo30 from './assets/Gallery/2022-07_stovykla/30.jpg';
import photo31 from './assets/Gallery/2022-07_stovykla/31.jpg';
import photo32 from './assets/Gallery/2022-07_stovykla/32.jpg';
import photo33 from './assets/Gallery/2022-07_stovykla/33.jpg';
import photo34 from './assets/Gallery/2022-07_stovykla/34.jpg';
import photo35 from './assets/Gallery/2022-07_stovykla/35.jpg';
import photo36 from './assets/Gallery/2022-07_stovykla/36.jpg';
import photo37 from './assets/Gallery/2022-07_stovykla/37.jpg';
import photo38 from './assets/Gallery/2022-07_stovykla/38.jpg';
import photo39 from './assets/Gallery/2022-07_stovykla/39.jpg';
import photo40 from './assets/Gallery/2022-07_stovykla/40.jpg';
import photo41 from './assets/Gallery/2022-07_stovykla/41.jpg';
import photo42 from './assets/Gallery/2022-07_stovykla/42.jpg';
import photo43 from './assets/Gallery/2022-07_stovykla/43.jpg';
import photo44 from './assets/Gallery/2022-07_stovykla/44.jpg';
import photo45 from './assets/Gallery/2022-07_stovykla/45.jpg';
import photo46 from './assets/Gallery/2022-07_stovykla/46.jpg';
import photo47 from './assets/Gallery/2022-07_stovykla/47.jpg';
import photo48 from './assets/Gallery/2022-07_stovykla/48.jpg';
import photo49 from './assets/Gallery/2022-07_stovykla/49.jpg';
import photo50 from './assets/Gallery/2022-07_stovykla/50.jpg';
import photo51 from './assets/Gallery/2022-07_stovykla/51.jpg';
import photo52 from './assets/Gallery/2022-07_stovykla/52.jpg';
import photo53 from './assets/Gallery/2022-07_stovykla/53.jpg';
import photo54 from './assets/Gallery/2022-07_stovykla/54.jpg';
import photo55 from './assets/Gallery/2022-07_stovykla/55.jpg';
import photo56 from './assets/Gallery/2022-07_stovykla/56.jpg';


const images = [
  {
    original: photo1,
    thumbnail: photo1,
    thumbnailHeight: '50',
  },
  {
    original: photo2,
    thumbnail: photo2,
    thumbnailHeight: '50',
  },
  {
    original: photo3,
    thumbnail: photo3,
    thumbnailHeight: '50',
  },
  {
    original: photo4,
    thumbnail: photo4,
    thumbnailHeight: '50',
  },
  {
    original: photo5,
    thumbnail: photo5,
    thumbnailHeight: '50',
  },
  {
    original: photo6,
    thumbnail: photo6,
    thumbnailHeight: '50',
  },
  {
    original: photo7,
    thumbnail: photo7,
    thumbnailHeight: '50',
  },
  {
    original: photo8,
    thumbnail: photo8,
    thumbnailHeight: '50',
  },
  {
    original: photo9,
    thumbnail: photo9,
    thumbnailHeight: '50',
  },
  {
    original: photo10,
    thumbnail: photo10,
    thumbnailHeight: '50',
  },
  {
    original: photo11,
    thumbnail: photo11,
    thumbnailHeight: '50',
  },
  {
    original: photo12,
    thumbnail: photo12,
    thumbnailHeight: '50',
  },
  {
    original: photo13,
    thumbnail: photo13,
    thumbnailHeight: '50',
  },
  {
    original: photo14,
    thumbnail: photo14,
    thumbnailHeight: '50',
  },
  {
    original: photo15,
    thumbnail: photo15,
    thumbnailHeight: '50',
  },
  {
    original: photo16,
    thumbnail: photo16,
    thumbnailHeight: '50',
  },
  {
    original: photo17,
    thumbnail: photo17,
    thumbnailHeight: '50',
  },
  {
    original: photo18,
    thumbnail: photo18,
    thumbnailHeight: '50',
  },
  {
    original: photo19,
    thumbnail: photo19,
    thumbnailHeight: '50',
  },
  {
    original: photo20,
    thumbnail: photo20,
    thumbnailHeight: '50',
  },
  {
    original: photo21,
    thumbnail: photo21,
    thumbnailHeight: '50',
  },
  {
    original: photo22,
    thumbnail: photo22,
    thumbnailHeight: '50',
  },
  {
    original: photo23,
    thumbnail: photo23,
    thumbnailHeight: '50',
  },
  {
    original: photo24,
    thumbnail: photo24,
    thumbnailHeight: '50',
  },
  {
    original: photo25,
    thumbnail: photo25,
    thumbnailHeight: '50',
  },
  {
    original: photo26,
    thumbnail: photo26,
    thumbnailHeight: '50',
  },
  {
    original: photo27,
    thumbnail: photo27,
    thumbnailHeight: '50',
  },
  {
    original: photo28,
    thumbnail: photo28,
    thumbnailHeight: '50',
  },
  {
    original: photo29,
    thumbnail: photo29,
    thumbnailHeight: '50',
  },
  {
    original: photo30,
    thumbnail: photo30,
    thumbnailHeight: '50',
  },
  {
    original: photo31,
    thumbnail: photo31,
    thumbnailHeight: '50',
  },
  {
    original: photo32,
    thumbnail: photo32,
    thumbnailHeight: '50',
  },
  {
    original: photo33,
    thumbnail: photo33,
    thumbnailHeight: '50',
  },
  {
    original: photo34,
    thumbnail: photo34,
    thumbnailHeight: '50',
  },
  {
    original: photo35,
    thumbnail: photo35,
    thumbnailHeight: '50',
  },
  {
    original: photo36,
    thumbnail: photo36,
    thumbnailHeight: '50',
  },
  {
    original: photo37,
    thumbnail: photo37,
    thumbnailHeight: '50',
  },
  {
    original: photo38,
    thumbnail: photo38,
    thumbnailHeight: '50',
  },
  {
    original: photo39,
    thumbnail: photo39,
    thumbnailHeight: '50',
  },
  {
    original: photo40,
    thumbnail: photo40,
    thumbnailHeight: '50',
  },
  {
    original: photo41,
    thumbnail: photo41,
    thumbnailHeight: '50',
  },
  {
    original: photo42,
    thumbnail: photo42,
    thumbnailHeight: '50',
  },
  {
    original: photo43,
    thumbnail: photo43,
    thumbnailHeight: '50',
  },
  {
    original: photo44,
    thumbnail: photo44,
    thumbnailHeight: '50',
  },
  {
    original: photo45,
    thumbnail: photo45,
    thumbnailHeight: '50',
  },
  {
    original: photo46,
    thumbnail: photo46,
    thumbnailHeight: '50',
  },
  {
    original: photo47,
    thumbnail: photo47,
    thumbnailHeight: '50',
  },
  {
    original: photo48,
    thumbnail: photo48,
    thumbnailHeight: '50',
  },
  {
    original: photo49,
    thumbnail: photo49,
    thumbnailHeight: '50',
  },
  {
    original: photo50,
    thumbnail: photo50,
    thumbnailHeight: '50',
  },
  {
    original: photo51,
    thumbnail: photo51,
    thumbnailHeight: '50',
  },
  {
    original: photo52,
    thumbnail: photo52,
    thumbnailHeight: '50',
  },
  {
    original: photo53,
    thumbnail: photo53,
    thumbnailHeight: '50',
  },
  {
    original: photo54,
    thumbnail: photo54,
    thumbnailHeight: '50',
  },
  {
    original: photo55,
    thumbnail: photo55,
    thumbnailHeight: '50',
  },
  {
    original: photo56,
    thumbnail: photo56,
    thumbnailHeight: '50',
  },
];
function Catalog3() {
  return (
    <div className="Gallery">
      <Header/>
      <div className='gallerynameblock'>
      <div className='goBack'>
    <Linkk to="/gallery"><ArrowBackIcon/><span>Atgal</span></Linkk>
    </div>
      <span className='galleryname'>2022-07 Stovykla</span>
      </div>
      <div className="Catalog3">
          <ImageGallery items={images}  showIndex={true}/>
      </div>
      <Footer/>
    </div>
  );
}

export default Catalog3;
