import './Reviews.css';
import Header from './Header.js'
import Footer from './Footer.js'
import FacebookIcon from '@mui/icons-material/Facebook';
function Reviews() {
  return (
    <div className="Reviews">
      <Header/>
      <div className="reviewnameblock">
      <a className="facebookIcon" href="https://www.facebook.com/talentu.aleja/reviews/?ref=page_internal"  target="blank"><FacebookIcon sx={{ fontSize: 60 }}/></a>
      <span className='reviewname'>ATSILIEPIMAI</span>
      </div>
        <div className='reviewsgrid'>
          <div className='reviewcardcol'>
            <div className='reviewcard'>
              <span>Eglė Mikalauskienė</span>
              <ul>
                <li><span>Labai smagus būrelis su atsidavusia muzikai mokytoja...💐 dukryte pirmi metai drįso seneliui ir padainuot ir 
                    eilėraštį padeklamuot...skatina saviraišką ,suteikia drąsos ir leidžia vaikams dar labiau pamilti muziką ir gyventi 
                    su ja 🤗 labai laukia užsiėmimų ❤❤❤</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Rita Galeckienė</span>
              <ul>
                <li><span>Puikus būrelis lavinantis vaikus ne tik muzikinėje, bet ir visose gyvenimo srityse. 
                  Ugdo pasitikėjimą savimi, moko nugalėti ne tik scenos bet ir kitas baimes. Niekada niekur dukra taip nesiveržė kaip į šį būrelį.
                    Mokytoja Auksė puiki specialistė, o jos gebėjimas pritraukti ir sudominti vaikus tiesiog stulbinantis....</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Regina Šležienė</span>
              <ul>
                <li><span>Miela Aukse. Stebini prasmingais ir labai reikalingais darbais. Sėkmės! Tau ir talentukams!</span></li>
              </ul>
            </div>
          </div>
          <div className='reviewcardcol'>
          <div className='reviewcard'>
              <span>Vaidas Petkevičius</span>
              <ul>
                <li><span>Puiki popamokinė veikla. Dukra labai džiaugiasi lankydama ir laukia kiekvienos pamokos. Mokytoja Auksė nuostabi pedagogė ir savo srities profesionalė. Pamokos įdomios, įtraukiančios ir visokeriopai lavinančios. Tik geriausi atsiliepimai.</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Toma Berulienė</span>
              <ul>
                <li><span>Nuostabus būrelis vaikams. Sūnus su džiaugsmu eina į kiekvieną užsiėmimą 🥰 Rekomenduoju visiems ♥️</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Irena Vaitkienė</span>
              <ul>
                <li><span>Mokytojos Auksės entuziazmas, išmonė, pozityvus bendravimas su vaikais yra šios kūrybinės kelionės sėkmės garantas. Sėkmės Jūsų komandai!</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Rita Beitnaraitė</span>
              <ul>
                <li><span>Kūrybinga MOKYTOJA ir puikaus balso savininkė! Sėkmės - atradusiems "Talentų alėją" </span></li>
              </ul>
            </div>
          </div>
          <div className='reviewcardcol'>
          <div className='reviewcard'>
              <span>Jurga Vizgaitytė-Navikienė</span>
              <ul>
                <li><span>Nerealus būrelis vaikams‼️ Visuomet taiiip laukiamas ir su tokiu užsidegimu lankomas.
                   Rekomenduojam visiems‼️‼️‼️ Mokytoja Auksė tikra profesionalė ir ne tik muzikoje, bet ir bendravime su vaikais, vaikai jà tiesiog myli.</span></li>
              </ul>
            </div>
          <div className='reviewcard'>
              <span>Kristina Raslauskienė</span>
              <ul>
                <li><span>Dukrytė nepaprastai laiminga lankydama užsiėmimus. 
                  Mokytoja Auksė yra savo srities profesionalė, kaip prieš daugiau nei 15 metų mokė mane ( muzikos pamoka būdavo viena iš įdomiausių, labiausiai įtraukiančių bei laukiamiausių pamokų), 
                  taip dabar mano dukrytės begalo mylimas ir laukiamas užsiėmimas. Mokytoja geba sudominti, paskatinti bei įtraukti vaikučius i užsiėmimus. O visų smagiausia yra matyti švytinčius vaikučius po pamokėlių!! 
                  Ačiū ir linkiu visokeriopos sėkmes! 🤗👏🎼</span></li>
              </ul>
            </div>
            <div className='reviewcard'>
              <span>Edita Fiodorovienė</span>
              <ul>
                <li><span>Jeigu dukrytė grįžusi tiesiog švyti, manau tai viską pasako 🙂</span></li>
              </ul>
            </div>
          </div>
        </div>
        <Footer/>
    </div>
  );
}

export default Reviews;